export default {
	init: (utils, gsap) => {

		// Shortcuts
		//////////////////////////////////////////////
		const shortcutsEl = document.querySelector('.js-shortcuts');
		if (shortcutsEl) {

            const shortcutsTl = gsap.timeline({ paused: true })

            shortcutsTl.set('.js-shortcuts-nav', { display: 'block' }, 'start')
                    .from('.js-shortcuts-nav', { autoAlpha: 0, y: 30, ease: 'power2.out', duration: 0.3 }, 'start')
                    .from('.js-shortcuts-nav-item', { autoAlpha: 0, y: 30, ease: 'power2.out', duration: 0.3, stagger: 0.05 }, '-=0.2')

            const shortcutsButton = document.querySelector('.js-shortcuts-button')

            if (shortcutsButton) {
                shortcutsButton.addEventListener('click', function(e){
                    e.preventDefault()
    
                    if (shortcutsButton.classList.contains('--active')) {
                        shortcutsTl.timeScale(2).reverse()
                        shortcutsButton.classList.remove('--active')
                    } else {
                        shortcutsTl.timeScale(1).play()
                        shortcutsButton.classList.add('--active')
                    }
                })
            }

            window.addEventListener('click', (e) => {
                if (!e.target.closest('.js-shortcuts')) {
                    shortcutsTl.timeScale(2).reverse()
                    shortcutsButton.classList.remove('--active')
                }
            })
        }

    }
}