export default {
	init: function(Swiper, Navigation){

        this.initSlider(Swiper, Navigation)

        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.initSlider(Swiper, Navigation)
        })

	},
    initSlider: function(Swiper, Navigation){

        const sliderEls = document.querySelectorAll('.js-mediaPhoto-swiper')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {

                const prevEl = slider.querySelector('.js-mediaPhoto-swiper-prev')
                const nextEl = slider.querySelector('.js-mediaPhoto-swiper-next')
                
                const sliderInstance = new Swiper(slider, {
                    slidesPerView: 1,
                    speed: 600,
                    spaceBetween: 0,
                    autoHeight: true,
                    modules: [Navigation],
                    navigation: {
                        prevEl: prevEl,
                        nextEl: nextEl
                    }
                })

                const controlsIndexEl = slider.querySelector('.js-controls-current')
                if (!controlsIndexEl) { 
                    console.error("Can't find controls in slider element")
                } else {
                    sliderInstance.on('slideChange', () => {
                        controlsIndexEl.textContent = sliderInstance.activeIndex + 1
                    })
                }
                

            })
        }

    }
}