export default {
	init: function(utils, Swiper, Navigation) {

        this.initAccordions()
        this.initSlider(Swiper, Navigation)

        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.initAccordions()
            this.initSlider(Swiper, Navigation)
        })
    
	},
    initAccordions: function(){
        const accordionEls = document.querySelectorAll('.js-accordion')

        if (accordionEls.length) {
            accordionEls.forEach(accordion => {

                const accordionButtons = accordion.querySelectorAll('.js-accordion-button')

                if (!accordionButtons.length) {
                    console.error(`Can't find accordion buttons`)
                } else {
                    accordionButtons.forEach(button => {
    
                        const accordionId = button.dataset.accordionId
            
                        if (accordionId) {
                            button.addEventListener('click', function(e){
                                e.preventDefault()
            
                                const currentButtons = accordion.querySelectorAll('.js-accordion-button.--active')
                                const currentContents = accordion.querySelectorAll('.js-accordion-content.--active')
        
                                currentButtons.forEach(button => {
                                    button.classList.remove('--active')
                                })
    
                                currentContents.forEach(content => {
                                    content.classList.remove('--active')
                                })
            
                                button.classList.add('--active')
        
                                const accordionTargeted = accordion.querySelector(`.js-accordion-content[data-accordion-id="${accordionId}"]`)
                                if (accordionTargeted) {
                                    accordionTargeted.classList.add('--active')
                                }
                                
                            })
                        }
            
                    })
                }
        
            })
        }
    },
    initSlider: function(Swiper, Navigation){
        const sliderEls = document.querySelectorAll('.js-accordion-slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {

                const prevEl = slider.parentNode.querySelector('.js-lateralAccordionSlider-prev')
                const nextEl = slider.parentNode.querySelector('.js-lateralAccordionSlider-next')
                
                const sliderInstance = new Swiper(slider, {
                    slidesPerView: 2,
                    speed: 300,
                    spaceBetween: 10,
                    modules: [Navigation],
                    navigation: {
                        prevEl: prevEl,
                        nextEl: nextEl
                    },
                    breakpoints: {
                        700: {
                          slidesPerView: 4,
                        }
                    },
                })

            })
        }
    }
}