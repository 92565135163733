export default {
	init: function() {
		
        this.handleClick();

        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.handleClick();
        })
        

	},
    handleClick: function(){
        const textListElements = document.querySelectorAll('.js-textList')

        if (textListElements.length) {
            textListElements.forEach(element => {

                const button = element.querySelector('.js-textList-button')

                if(button){
                    button.addEventListener('click', function(e){
                        e.preventDefault()

                        element.classList.add('--active')
                    })
                }

            })
        }
    }
}