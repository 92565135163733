export default {
	init: (utils) => {

		// Footer buttons
		//////////////////////////////////////////////
		const footerButtons = document.querySelectorAll('.js-footer-button');
		if (!footerButtons.length) {
			console.error(`Can't find footer buttons`)
		} else {
			footerButtons.forEach(button => {
				const col = button.closest('.footerNav__col');
	
				button.addEventListener('click', function(e){
					e.preventDefault();
					col.classList.toggle('--active');
				})
			})
		}
		
	}
}