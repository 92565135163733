export default {
	init: function(Swiper, Navigation) {
        
        this.initSlider(Swiper, Navigation);

        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.initSlider(Swiper, Navigation);
        })

	},
    initSlider: function(Swiper, Navigation){

        const sliderEls = document.querySelectorAll('.js-pictoSlider-swiper')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {

                const sliderBlock = slider.closest('.js-pictoSlider')
                const prevEl = sliderBlock.querySelector('.js-pictoSlider-swiper-prev')
                const nextEl = sliderBlock.querySelector('.js-pictoSlider-swiper-next')
                
                const sliderInstance = new Swiper(slider, {
                    slidesPerView: 1,
                    speed: 600,
                    spaceBetween: 30,
                    modules: [Navigation],
                    navigation: {
                        prevEl: prevEl,
                        nextEl: nextEl
                    }
                })

                const controlsIndexEl = sliderBlock.querySelector('.js-controls-current')

                if (!controlsIndexEl) { 
                    console.error("Can't find controls in slider element")
                } else {
                    sliderInstance.on('slideChange', () => {
                        controlsIndexEl.textContent = sliderInstance.activeIndex + 1
                    })
                }

            })
        }
    }
}