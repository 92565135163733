export default {
	init: function(utils) {

        this.tabsListButtons = document.querySelectorAll('.js-tabList-button');
        this.handleButtonsClick()

	},

    handleButtonsClick: function(){
        if (this.tabsListButtons.length) {
            this.tabsListButtons.forEach(button => {
                const tab_id = button.dataset.id;
                const permalink = button.href;
                const meta_title = button.dataset.seoTitle;

                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.getTabWithAjax(tab_id, meta_title);
                    this.setActiveClass(button);
                    this.addOrReplaceTabParam(permalink);
                })
            })
        }

    },

    getTabWithAjax: function(tab_id, meta_title){

        const container = document.querySelector('.js-tabContent');
        const renderContainer = document.querySelector('.js-tabList-render')

        const event = new CustomEvent("dom:updated");
        const body = document.querySelector('body')

        $.ajax({
            url: wordpress_vars.ajax_url,
            type: 'POST',
            data: {
                action: 'get_tab_content',
                tab_id: tab_id
            },
            beforeSend: function(){
                container.classList.add('--loading')
                renderContainer.classList.add('--out')
            },
            success: function(response) {
                renderContainer.innerHTML = response;
                document.title = meta_title;
                window.scroll({
                    top: container.offsetTop - 80,
                    left: 0,
                    behavior: "smooth",
                });
                
                body.dispatchEvent(event);
            },
            complete: function(){
                container.classList.remove('--loading')
                renderContainer.classList.remove('--out')
            },
            error: function(xhr, status, error) {
                console.error('AJAX Error:', status, error);
            }
        });

    },

    setActiveClass: function(button){
        this.tabsListButtons.forEach(button => {
            button.classList.remove('--active')
        })
        button.classList.add('--active');
    },

    /**
     * Add or replace tab parameter in URL
     * @param {String} permalink
     */
    addOrReplaceTabParam: function(permalink) {
        window.history.pushState({}, '', permalink);
    }
}