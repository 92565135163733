export default {
	init: (utils) => {

        // Set CSS Variables
        ////////////////////////////////////
        utils.setCSSVariables('--viewport-height', `${window.innerHeight}px`)
        window.addEventListener('resize', () => utils.setCSSVariables('--viewport-height', `${window.innerHeight}px`))

        // Title space
        ////////////////////////////////////
        function addNonBreakingSpace(string) {
            const lastSpace = string.lastIndexOf(' ');
            
            if (lastSpace === -1) {
                return string;
            }

            const lastWord = string.substring(lastSpace + 1);
            const newString = string.substring(0, lastSpace) + String.fromCharCode(160) + lastWord;
        
            return newString;
        }

        if (window.innerWidth > 1020) {
            const headings = document.querySelectorAll('h1, h2, h3, h4, h5')
            if (headings.length) {
                headings.forEach(heading => {
                    heading.textContent = addNonBreakingSpace(heading.textContent)
                })
            }
        }
        
        

    }
}