export default {
	init: (utils, gsap, ScrollTrigger) => {

		// Desktop menu
        /////////////////////////////////////////////////////////////////////////////////
		const dropdownBtns = document.querySelectorAll('.js-header-dropdown-btn')
		if (dropdownBtns.length){

			dropdownBtns.forEach((btn, index) => {

				const dropdownEl = btn.querySelector('.headerMainNavSub');
				if (dropdownEl) {

					//Animation timeline
					const drodownTl = gsap.timeline({ paused: true })
					drodownTl.set(dropdownEl, { display: "block" }, 'start')
							.from(dropdownEl, { autoAlpha: 0, y: 40, duration: 0.3, ease: 'power1.inOut'}, 'start')

					//Events
					btn.addEventListener('mouseover', function(e){
						drodownTl.play();
					})
					
					btn.addEventListener('mouseleave', function(e){
						drodownTl.reverse();
					})

				}

			})
		}

		// Message
        /////////////////////////////////////////////////////////////////////////////////
		const messageEl = document.querySelector('.js-header-message')
		
		if (messageEl) {
			const messageDate = messageEl.dataset.modified;
			const messageButton = document.querySelector('.js-header-message-close');

			messageButton.addEventListener('click', function(e){
				e.preventDefault()
				messageEl.classList.add('--inactive')
				utils.setCookie('message_disabled', messageDate, 7);
				utils.setCSSVariables('--header-height', `${header.offsetHeight}px`)
			})
		}

		// Scrolling header
		/////////////////////////////////////////////////////////////
		const body = document.querySelector('body');
	  
		ScrollTrigger.create({
			markers: false,
			trigger: body,
			start: 'top -5%',
			onUpdate: self => {
				if (self.direction === 1) {
					body.classList.add('--scrolling-down');
					body.classList.remove('--scrolling-up');
				} else if(self.progress === 0) {
					body.classList.remove('--scrolling-up');
				} else {
					body.classList.add('--scrolling-up');
					body.classList.remove('--scrolling-down');
				}
			},
			onLeaveBack: () => {
				body.classList.remove('scrolling-up');
			},
		});

	}
}