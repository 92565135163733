export default {
	init: (Swiper, Navigation) => {
		
        // Post summary slider
        /////////////////////////////////////////////////////////////////////////////
        const sliderEL = document.querySelector('.js-postSummary-slider')
        
        if (sliderEL) {
            const prevEl = document.querySelector('.js-postSummary-slider-prev')
            const nextEl = document.querySelector('.js-postSummary-slider-next')

            const sliderInstance = new Swiper(sliderEL, {
                slidesPerView: 'auto',
                speed: 300,
                centerInsufficientSlides: true,
                modules: [Navigation],
                navigation: {
                    prevEl: prevEl,
                    nextEl: nextEl
                },
                freeMode: true,
            })
        }

	}
}