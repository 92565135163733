export default {
	init: function(utils, gsap){
        this.handleVideo(gsap)

        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.handleVideo(gsap)
        })
	},
    handleVideo: function(gsap){
        const videoBlocks = document.querySelectorAll('.js-mediaVideo');
        if (videoBlocks.length > 0) {
            videoBlocks.forEach(videoBlock => {

                const videoPlayButton = videoBlock.querySelector('.js-mediaVideo-button');

                if (videoPlayButton) {
                    const videoType = videoPlayButton.dataset.videoType
                    const timeline = gsap.timeline({ paused: true })
                    const previewImage = videoBlock.querySelector('.js-mediaVideo-preview')
                    const iframe = videoBlock.querySelector('.mediaVideoIframe')
        
                    timeline.to(videoPlayButton, {autoAlpha: 0, y: -30, duration: 0.3, ease: "power2.out"}, 'start')
                            .to(previewImage, {autoAlpha: 0, duration: 0.3, ease: "power2.inOut"}, '-=0.2')
                            // .add(() => { iframe.contentWindow.postMessage(`["play"]`, '*') }, 'start')
        
                    videoPlayButton.addEventListener('click', () => {
                        timeline.play();

                        let iframeElement = videoBlock.querySelector('.js-mediaVideo-iframe-el')
                        let src = iframeElement.src;
                        iframeElement.src = '';
                        iframeElement.src = src + '&autoplay=1&muted=0';
                    })
                }
    
            })
        }
    }
}