export default {
	init: (WaveSurfer) => {
		
        // Podcast
        /////////////////////////////////////////////////////////////////////////////
        const audioEls = document.querySelectorAll('.js-audio-widget')
        window.widgetPlaying = null;
        
        if(audioEls.length){

            audioEls.forEach(audioEl => {

                //Init player
                const waveContainer = audioEl.querySelector('.js-audio-widget-waves')
                const audioUrl = waveContainer.dataset.audioUrl;
                const waveColor = waveContainer.dataset.waveColor;

                const wavesurfer = WaveSurfer.create({
                    container: waveContainer,
                    waveColor: '#CCE3E5',
                    progressColor: waveColor,
                    url: audioUrl,
                    barWidth: 4,
                    barGap: 5,
                    barRadius: 0,
                    barHeight: 1,
                    cursorWidth: 0,
                    height: waveContainer.dataset.style === "small" ? 35 : 65
                })

                // Play audio
                const playButton = audioEl.querySelector('.js-audio-widget-button')

                if (playButton) {
                    playButton.addEventListener('click', function(e){
                        e.preventDefault();

                        if (window.widgetPlaying) {
                            window.widgetPlaying.pause();
                        }

                        if (playButton.classList.contains('--active')) {
                            wavesurfer.pause();
                            playButton.classList.remove('--active')
                            window.widgetPlaying = null;
                        } else {
                            wavesurfer.play();
                            playButton.classList.add('--active')
                            window.widgetPlaying = wavesurfer;
                        }
                    })
                }

            })

        }

	}
}