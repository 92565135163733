export default {
	init: function() {
		this.bindEvents();
	},

	bindEvents: function() {
		const formEL = document.querySelector('.js-pcForm');

		formEL.addEventListener('submit', (e) => {
			e.preventDefault();

			if (this.validateForm(e)) {
				let formData = new FormData(e.currentTarget);

				formData.append('action', 'proximite_client_submit_form');

				$.ajax({
					url: wordpress_vars.ajax_url,
					type: 'POST',
					contentType: false,
					processData: false,
					dataType: 'json',
					data: formData,
					complete: function(response){
						if (response.responseJSON.success) {
							formEL.classList.add('--hidden')

							document.querySelector('.js-pcForm-success').classList.add('--active')
						} else {
							console.log('Error');
						}
					}
				});
			}
		});
	},
	validateForm: function(e) {
		const form = e.target;
		const fields = Array.from(form.elements);
		
		fields.forEach(field => {
			if (field.closest('.inputGroup')) {
				if (field.checkValidity()) {
					form.classList.remove('--invalid')
					field.closest('.inputGroup').classList.remove('--invalid');
				} else {
					form.classList.add('--invalid')
					field.closest('.inputGroup').classList.add('--invalid');
				}
			}
		});

		if (!form.checkValidity()) {
			return false;
		} else {
			return true;
		}
	}
}
