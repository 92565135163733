export default {
	init: (utils, gsap) => {

		// Mobile nav opening animation
        /////////////////////////////////////////////////////////////////////////////////
        const mobileNavButton = document.querySelector('.js-mobileNav-button');

        const mobileNavTl = gsap.timeline({ paused: true })

        mobileNavTl.set('.js-mobileNav', { display: "block" }, 'start')
                   .from('.js-mobileNav-layer', { scaleY: 0, duration: 0.4, ease: "power2.inOut" }, 'start')
                   .from('.js-mobileNav-item', { autoAlpha: 0, y: 40, duration: 0.6, stagger: 0.087, ease: "power2.out" }, '-=0.3')

        // Opening
        if (!mobileNavButton) {
            console.error("Can't find the mobile nav button element")
        } else {
            mobileNavButton.addEventListener('click', () => {
                mobileNavTl.timeScale(1).play()
                utils.disableScroll()
            })
        }

        //Closing
        const mobileNavCloseButton = document.querySelector('.js-mobileNav-close')
        if (!mobileNavCloseButton) {
            console.error("Can't find the mobile nav close button element")
        } else {
            mobileNavCloseButton.addEventListener('click', () => {
                mobileNavTl.timeScale(2).reverse()
                utils.enableScroll()
            })
        }


        // Mobile nav subnav animation
        /////////////////////////////////////////////////////////////////////////////////
        const mobileNavEl = document.querySelector('.js-mobileNav')
        const wrapper = document.querySelector('.js-mobileNav-wrapper')
        const subnavButtons = document.querySelectorAll('.js-mobileNav-subnav-btn');
        if (!subnavButtons.length) {
            console.error("Can't find the subnav buttons elements")
        } else {
            subnavButtons.forEach(button => {

                const subnavTarget = button.dataset.target;
                const subnavEl = document.querySelector(`.mobileNavMenuSub[data-subnav="${subnavTarget}"]`)
                const closeButton = subnavEl.querySelector('.js-mobileNav-subnav-close');
                const timeline = gsap.timeline({ paused: true })
    
                timeline.set(subnavEl, { display: "block" }, 'start')
                        .to('.js-mobileNav-slide-base', {autoAlpha: 0, duration: 0.3 }, 'start')
                        .to(wrapper, { x: "-100%", duration: 0.6, ease: "power2.inOut" }, 'start')
    
                button.addEventListener('click', () => {
                    timeline.timeScale(1).play()
                    mobileNavEl.scrollTop = 0
                })
    
                closeButton.addEventListener('click', () => {
                    timeline.timeScale(2).reverse()
                    mobileNavEl.scrollTop = 0
                })
    
            })
        }

	}
}