export default {
	init: () => {
		
        // Spot display more
        /////////////////////////////////////////////////////////////////////////////
        const spotButtons = document.querySelectorAll('.js-spot-button')

        if (spotButtons.length) {
            spotButtons.forEach(button => {

                if(button){
                    button.addEventListener('click', function(e){
                        e.preventDefault()

                        const spotItems = button.parentNode.querySelectorAll('.js-spot')

                        if (spotItems.length) {
                            spotItems.forEach(item => {
                                item.classList.add('--active')
                            })
                            button.classList.add('--unactive')
                        }

                        
                    })
                }

            })
        }

	}
}