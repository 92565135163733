export default {
	init: (utils, gsap) => {

		// Search aside
        /////////////////////////////////////////////////////////////////////////////////
        const timelineSearch = gsap.timeline({ paused: true })

        timelineSearch.set('.js-search-aside', { display: "block" }, 'start')
                .from('.js-search-aside-layer', { scaleY: 0, duration: 0.5, ease: "power2.out" }, 'start')
                .from('.js-search-aside-anim-item', { autoAlpha: 0, y: 50, duration: 1, stagger: 0.1, ease: "power2.out" }, '-=0.4')
        
        //open
        const searchButton = document.querySelector('.js-header-search')

        if (searchButton) {
            searchButton.addEventListener('click', function(e){
                e.preventDefault()
                timelineSearch.timeScale(1).play()
                utils.disableScroll();
            })
        }

        //close
        const closeSearchButton = document.querySelector('.js-search-aside-close')

        if (closeSearchButton) {
            closeSearchButton.addEventListener('click', function(e){
                e.preventDefault()
                timelineSearch.timeScale(1.5).reverse()
                utils.enableScroll();
            })
        }

	}
}