export default {
    init: (Popin) => {
        
        // Popin comments
        /////////////////////////////////////////////////////////////////////////////
        const popinEl = document.querySelector('.js-popin-comments')
        if (popinEl) {

            const popinInstance = new Popin(popinEl, {
                openBtnCSSSelector: '.js-popin-comments-open',
                closeBtnCSSSelector: '.js-popin-comments-close',
                popinBackCSSSelector: '.js-popin-comments-back',
                popinContentCSSSelector: '.js-popin-comments-content'
            })

		}

    }
}