export default {
	init: (Swiper, Pagination) => {

        // Slider
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js-relatedProgram-swiper')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {

                // const prevEl = sliderBlock.querySelector('.js-videoGrid-swiper-prev')
                // const nextEl = sliderBlock.querySelector('.js-videoGrid-swiper-next')
                
                const sliderInstance = new Swiper(slider, {
                    slidesPerView: 1,
                    speed: 600,
                    spaceBetween: 30,
                    modules: [Pagination],
                    breakpoints: {
                        650: {
                          slidesPerView: 1.5,
                        },
                        800: {
                            slidesPerView: 2,
                        },
                    },
                    pagination: {
                        el: '.js-relatedProgram-swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    // navigation: {
                    //     prevEl: prevEl,
                    //     nextEl: nextEl
                    // }
                })

            })
        }

	}
}