export default {
	init: function(utils, Popin) {
		const popinEl = document.querySelector('.js-popin-newsletter')

        if (popinEl) {

            const popinInstance = new Popin(popinEl, {
                openBtnCSSSelector: '.js-popin-newsletter-open',
                closeBtnCSSSelector: '.js-popin-newsletter-close',
                popinBackCSSSelector: '.js-popin-newsletter-back',
                popinContentCSSSelector: '.js-popin-newsletter-content'
            })

		}
	}
}